<template>
  <div class="ccc">
    <TopHead></TopHead>
    <logoNav></logoNav>
    <div class="uc_con">

      <div class="uc_title d_f ali_c flex_1">
        <div class="uc_green"></div>
        <div>授信额度</div>
      </div>
      <div class="fn_mon d_f ali_c">
        <div class="flex_1"></div>
        <div class="d_f ali_b">
          <div class="mr">
            <span>总授信额度：</span>
            <span class="f_w">{{creditAmount ? Number(creditAmount/100).toFixed(2) :'0'}}</span>
          </div>
          <div>
            <span>可使用授信额度：</span>
            <span class="red_text">{{creditBalance ? Number(creditBalance/100).toFixed(2) :'0'}}</span>
          </div>
        </div>
      </div>


      <div class="t_l">
        <el-form :inline="true" :model="pageInfo.data" class="demo-form-inline">
          <el-form-item label="操作时间">
            <el-date-picker
              v-model="value1"
              value-format="YYYY-MM-DD"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="关联订单号">
            <el-input placeholder="关联订单号" v-model="pageInfo.data.bizId"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="resetSearch">重置</el-button>
            <el-button type="primary" @click="search">查询</el-button>
          </el-form-item>
        </el-form>
      </div>

      <el-table
        ref="multipleTable"
        :data="list"
        stripe
        :header-cell-style="{'text-align':'center','background-color': '#F4F4F4','font-size': '14px','color': '#333','font-weight': 'bold'}"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column label="分销商名称" align="center">
          <template #default="scope">{{ scope.row.businessName }}</template>
        </el-table-column>
        <el-table-column prop="originalAmount" label="原授信额度" align="center">
          <template #default="scope">{{ scope.row.originalAmount ? Number(scope.row.originalAmount/100).toFixed(2) : 0 }}</template>
        </el-table-column>
        <el-table-column prop="amount" label="当前变动金额" align="center" show-overflow-tooltip>
          <template #default="scope">{{ scope.row.amount ? Number(scope.row.amount/100).toFixed(2) : 0 }}</template>
        </el-table-column>
        <el-table-column prop="currentBalance" label="当前可用授信额度" align="center" show-overflow-tooltip>

          <template #default="scope">{{ scope.row.currentBalance ? Number(scope.row.currentBalance/100).toFixed(2) : 0 }}</template>
        </el-table-column>
        <el-table-column prop="changeItem" label="额度变动原因" align="center" show-overflow-tooltip>
          <template #default="scope">
            <span v-if="scope.row.changeItem == 1">购票</span>
            <span v-if="scope.row.changeItem == 2">退票</span>
            <span v-if="scope.row.changeItem == 3">充值</span>
            <span v-if="scope.row.changeItem == 4">提现</span>
            <span v-if="scope.row.changeItem == 5">还款</span>
            <span v-if="scope.row.changeItem == 6">减扣</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderNumber" label="关联订单号" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="creatorTime" label="操作时间" align="center" show-overflow-tooltip></el-table-column>
      </el-table>
      <div class="t_r mt_12">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          v-model:currentPage="pageInfo.pageIndex"
          :page-sizes="$global.pageArr"
          :page-size="pageInfo.pageSize"
          layout="sizes, prev, pager, next"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "quota",
    data() {
      return {
        value1:'',
        searchInfo:{},
        creditAmount:0,
        creditBalance:0,
        list:[],
        balance:0,
        total:0,
        pageInfo:{
          pageIndex:1,
          pageSize:10,
          data:{'changeBizType':'2','bizId':'','beginTime':'','endTime':''}
        },
      }
    },
    created() {
      this.getBusinessWallet();
      this.search();
    },
    methods: {
      resetSearch(){
        this.value1 = '';
        this.pageInfo.data.bizId = '';
        this.pageInfo.data.beginTime='';
        this.pageInfo.data.endTime='';
      },
      search(){
        if(this.value1.length){
          this.pageInfo.data.beginTime = this.value1[0];
          this.pageInfo.data.endTime = this.value1[1];
        }
        this.$api.queryPageFi(this.pageInfo).then(res => {
          this.total = res.data.recordCount;
          this.list = res.data.rows;
        })
      },
      handleSizeChange(val) {
        this.pageInfo.pageSize = val;
        this.pageInfo.pageIndex = 1;
        this.search();
      },
      handleCurrentChange(val) {
        this.pageInfo.pageIndex = val;
        this.search();
      },
      //查询账户余额
      getBusinessWallet(){
        this.$api.getBusinessWallet().then(res => {
          this.creditAmount = res.data.creditAmount;
          this.creditBalance = res.data.creditBalance;
        })
      },
    }
  }
</script>

<style scoped lang="scss">

  .fn_mon{
    .mr{
      margin-right: 65px;
    }
    .red_text{
      font-size: 42px;
      font-weight: bold;
      color: #FF2626;
    }
    margin-bottom: 20px;
    font-size: 20px;
    justify-items: right;
    border-bottom: 7px solid #F0F1F3;
    padding-bottom: 15px;
  }
  .uc_title{
    margin-bottom: 0;
  }
</style>
